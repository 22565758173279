/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	$color-pink: rgb(201, 44, 153);
	$color-dark-blue: rgb(0, 32, 92);

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		background-image: url(../images/bg-body.jpg);
		font-family: 'Ubuntu', sans-serif;

		color: #666666;
		font-size: 13px;
	}

	a
	{
		color: #559cbe;
	}

	h1
	{
		margin: 0 0 20px 0;

		font-size: 26px;
		font-weight: normal;
		color: $color-pink;
		line-height: 1.2em;
	}

	h2
	{
		margin: 0 0 15px 0;

		font-size: 20px;
		line-height: 1.2em;
		color: #666666;
	}

	h3
	{
		margin: 0 0 15px 0;

		font-size: 16px;
		line-height: 1.2em;
		color: #666666;
	}

	.row-spaced
	{
		margin-bottom: 30px;
	}

	.btn
	{
		background-image: none;
	}

	.fc-day-grid-event {
        cursor: pointer;
	}

	.text-right {
		text-align: right !important;
	}

	.text-center {
		text-align: center !important;
	}

	.multiselect input {
		border: none !important;
		box-shadow: none !important;
		background: none;
	}

	.pagination {
		margin: 0;
	}

	.mb-none {
		margin-bottom: 0;
	}

	.btn.filter-btn {
		background: no-repeat;
		box-shadow: none;
		text-shadow: none;
		border-color: #cccccc;
	}

	input.micro-input, select.micro-input {
		box-shadow: none;
		border-top: none;
		border-left: none;
		border-right: none;
		border-radius: 0;
		padding: 0 4px;

		height: 17px;
		line-height: 17px;
	}

	.table-join-bottom {
		margin-bottom: 0;
		border-bottom: none;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		th, td {
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}

	.table-join-top {
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		th, td {
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
		}
	}

	.ml-0 {
		margin-left: 0 !important;
	}

	.clear-both {
		clear: both;
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		margin: 30px 0 0;
		padding: 20px 20px 5px;
		box-sizing: border-box;
		width: 100%;

		background-color: #ffffff;
		box-shadow: 8px 8px 0 0 #dddddd;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 979px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/* Landscape phones and down */
	@media (max-width: 480px) {

	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.navbar
	{
		margin: 15px 0 0 0;

		background: transparent;
		border-bottom: 1px solid $color-dark-blue;
	}

	.navbar-inner
	{
		min-height: 28px;
		margin: 0;
		padding: 0;

		border: none;
		background: transparent;
		box-shadow: none;
	}

	.navbar .nav > li > a
	{
		padding: 4px 15px;

		background: $color-dark-blue;
		border-right: 1px solid #ffffff;

		text-shadow: none;
		color: #ffffff;
		font-size: 13px;
	}

	.navbar .nav > li > a:focus,
	.navbar .nav > li > a:hover
	{
		background-color: $color-pink;

		color: #ffffff;
	}

	.navbar .nav > .active > a,
	.navbar .nav > .active > a:hover,
	.navbar .nav > .active > a:focus
	{
		box-shadow: none;
		background-color: $color-pink;

		text-shadow: none;
		color: #ffffff;
	}

	.nav-tabs > .active > a,
	.nav-tabs > .active > a:hover,
	.nav-tabs > .active > a:focus
	{
		background-color: $color-pink;

		color: #ffffff;
	}

	.navbar .btn-navbar
	{
		text-align: center;
		background-color: lighten($color-dark-blue, 50%);
		background-image: none;
	}

	/* Large desktop */
	@media (min-width: 1200px) {
		.navbar .nav > li > a
		{
			padding: 4px 20px;

			font-size: 14px;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 979px) {
		.navbar .btn-navbar
		{
			width: 100%;
			display: block;
			box-sizing: border-box;
			margin: 10px 0;
			padding: 10px 0;

			color: #333333;
			text-shadow: none;
		}

		.navbar .btn-navbar:hover,
		.navbar .btn-navbar:focus,
		.navbar .btn-navbar:active,
		.navbar .btn-navbar.active,
		.navbar .btn-navbar.disabled,
		.navbar .btn-navbar[disabled]
		{
			color: $color-pink;
			background-color: lighten($color-dark-blue, 50%);
			background-image: none;
		}

		.navbar .nav > li > a
		{
			padding: 14px 20px;

			border-right: none;

			font-size: 14px;
			text-align: center;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar .btn-navbar
		{
			width: 100%;
			display: block;
			box-sizing: border-box;
			margin: 10px 0;
			padding: 10px 0;

			color: #333333;
			text-shadow: none;
		}

		.navbar .btn-navbar:hover,
		.navbar .btn-navbar:focus,
		.navbar .btn-navbar:active,
		.navbar .btn-navbar.active,
		.navbar .btn-navbar.disabled,
		.navbar .btn-navbar[disabled]
		{
			color: $color-pink;
			background-color: lighten($color-dark-blue, 50%);
			background-image: none;
		}

		.navbar .nav > li > a
		{
			padding: 14px 20px;

			border-right: none;

			font-size: 14px;
			text-align: center;
		}
	}

	/* Landscape phones and down */
	@media (max-width: 480px) {

	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.box-wrapper
	{
		width: 100%;
		box-sizing: border-box;
		padding: 15px;

		margin: 0 0 15px 0;
	}

	.box-style1
	{
		background-color: #f2f2f2;
	}

	.page
	{
		margin: 0 0 30px 0;
		padding: 20px;
		box-sizing: border-box;
		width: 100%;

		background-color: #ffffff;
		box-shadow: 8px 8px 0 0 #dddddd;
	}

	.mobile-only-inline
	{
		display: none;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	td.checkpoint-label
	{
		background: #666!important;
		color: white;
        font-weight: bold;
	}

		td.checkpoint-label:hover
		{
			background: #666;
		}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 979px) {
		.table
		{
			font-size: 12px;
		}

		.row-fluid .span10,
		.row-fluid .span2
		{
			width: 100% !important;
			margin: 0 !important;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.table
		{
			font-size: 12px;
		}

		table
		{
			border: none !important;
		}

		/* Force table to not be like tables anymore */
		table, thead, tbody, td, tr {
			display: block;
		}

		table th
		{
			display: none;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr { margin-bottom: 40px; }

		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			border-right: 1px solid #eee;
			position: relative;
			padding-left: 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

		.mobile-only-inline
		{
			display: inline;
		}
	}

	/* Landscape phones and down */
	@media (max-width: 480px) {

	}

	/*=========================================================================*/

	/* [Form Template Styles] */

	.image-library {
		a:hover, a:focus {
			text-decoration: none;
		}

		.image-library-item-col {
			margin-bottom: 30px;

			.image-library-item {
				box-sizing: border-box;
				height: 100%;
				background: #f1f2f2;
				padding: 4px;

				h4 {
					padding: 0 6px;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Form Template Styles] */

	.form-horizontal label.control-label
	{
		font-weight: bold;
	}

	.form-horizontal .control-group
	{
		margin-bottom: 10px;
		padding-bottom: 10px;

		//border-bottom: 1px solid #eeeeee;
	}

	.form-horizontal .control-group:last-child
	{
		border-bottom: 0;
	}

    input.hasDatepicker {
        border-radius: 4px 0 0 4px;

        ~ .ui-datepicker-trigger {
            background: #f5f5f5;
            border: 1px solid #cccccc;
            border-color: #e6e6e6 #e6e6e6 #bfbfbf;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            border-bottom-color: #b3b3b3;
            border-radius: 0 4px 4px 0;
            padding: 3px 6px 4px;

            &:hover, &:focus {
                background: #e6e6e6;
            }

            &:active {
                box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
            }
        }
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 979px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/* Landscape phones and down */
	@media (max-width: 480px) {

	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		padding: 0 30px 30px 30px;
		box-sizing: border-box;
		width: 100%;

		font-size: 12px;
	}

	.footer ul
	{
		margin: 0;
		padding: 0;

		list-style-type: none;
	}

	.footer ul li
	{
		float: left;
		margin-right: 20px;
	}

	.footer ul li:last-child
	{
		margin: 0;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 979px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			text-align: center;
		}
	}

	/* Landscape phones and down */
	@media (max-width: 480px) {

	}

	/*=========================================================================*/

	/* [Nivo Slider Styles] */

	.theme-default .nivoSlider{
		position: relative;
		background: #fff url(../images/loading.gif) no-repeat 50% 50%;
		margin-bottom: 10px;
	}

	.theme-default .nivoSlider img
	{
		position: absolute;
		top: 0px;
		left: 0px;
		display: none;
	}

	.theme-default .nivoSlider a
	{
		border: 0;
		display: block;
	}

	.theme-default .nivo-controlNav
	{
		margin-top: -70px;
		text-align: center;
		padding: 20px 0;
		position: relative;
		z-index: 1000;
	}

	@media (max-width: 767px) {
		.theme-default .nivo-controlNav
		{
			display: none;
		}
	}

	.theme-default .nivo-controlNav a
	{
		display: inline-block;
		width: 22px;
		height: 22px;
		background: url(../images/bullets.png) no-repeat;
		text-indent: -9999px;
		border: 0;
		margin: 0 2px;
	}

	.theme-default .nivo-controlNav a.active
	{
		background-position: 0 -22px;
	}

	.theme-default .nivo-directionNav a
	{
		display: block;
		width: 30px;
		height: 30px;
		background: url(../images/arrows.png) no-repeat;
		text-indent: -9999px;
		border: 0;
		opacity: 0;
		-webkit-transition: all 200ms ease-in-out;
		-moz-transition: all 200ms ease-in-out;
		-o-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;
	}

	.theme-default:hover .nivo-directionNav a
	{
		opacity: 1;
	}

	.theme-default a.nivo-nextNav
	{
		background-position: -30px 0;
		right: 15px;
	}

	.theme-default a.nivo-prevNav
	{
		left: 15px;
	}

	.theme-default .nivo-caption
	{
		font-family: Arial, sans-serif;
	}

	.theme-default .nivo-caption a
	{
		color: #fff;
		border-bottom: 1px dotted #fff;
	}

	.theme-default .nivo-caption a:hover
	{
		color: #fff;
	}

	.theme-default .nivo-controlNav.nivo-thumbs-enabled
	{
		width: 100%;
	}

	.theme-default .nivo-controlNav.nivo-thumbs-enabled a
	{
		width: auto;
		height: auto;
		background: none;
		margin-bottom: 5px;
	}

	.theme-default .nivo-controlNav.nivo-thumbs-enabled img
	{
		display: block;
		width: 120px;
		height: auto;
	}

	#delete-item,
	#send-email,
	.white-block,
	.white-popup-block
	{
		background: #FFF;
		padding: 20px 30px;
		text-align: left;
		max-width: 650px;
		margin: 40px auto;
		position: relative;
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	/*=========================================================================*/

	/* [Report button] */

	.help-button {
		position: fixed;
		bottom: 0;
		right: 0;
		margin-bottom: 0;
	}

	.help-button .report-button {
		background: #f5f5f5;
		border-radius: 3px 0 0 0;
		border-bottom: 0;
		border-right: 0;

		-webkit-box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
	}

	#issueModal .controls input,
	#issueModal .controls textarea {
		width: 100%;
		box-sizing: border-box;
		height: auto;
	}

	.small-dialog {
		background: white;
		padding: 20px 30px;
		text-align: left;
		max-width: 800px;
		margin: 40px auto;
		position: relative;
	}

	.custom-combobox {
		position: relative;
		display: inline-block;
	}
	.custom-combobox-toggle {
		position: absolute;
		top: 0;
		bottom: 0;
		margin-left: -1px;
		padding: 0;
	}
	.custom-combobox-input {
		margin: 0;
		padding: 5px 10px;
	}

	.mfp-iframe-scaler {
		.mfp-close {
			&::before {
				content: "\03a7";
				font-size: 16px;
				cursor: pointer;
			}
		}
	}
